import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import dSocial from "./../assets/icons/dSocial.png";
import mobile from "./../assets/images/imageMobile.png";
import american from "./../assets/images/americanMade.png";
import l1feMinistry from "./../assets/images/l1feMinistry.png";
import LeftColoumn from "../components/shared/LeftColoumn";


const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container fluid className="vh-100 login-wrapper wrapper">
        <Row className="h-100">
          <LeftColoumn />
          <Col xl={7} className="right-col mt-5">
            <Container className="h-100">
              <Row className="h-100">
                <Col xl={8} lg={12} className="left-col-child">
                  <div>
                    <img src={dSocial} alt="site_icon" />
                    <h1>socialize without censorship</h1>
                    <p className="mt-4 ">
                      <span className="text-black">Introducing dSocial</span>, a
                      completely decentralized social network powered by its
                      users. Say goodbye to cancel culture.
                    </p>
                    <Button
                      className="custom-btn"
                      variant="dark"
                      onClick={() => navigate("/login")}
                    >
                      Signup For Early Access
                    </Button>
                    <img src={american} alt="american_icon" />
                  </div>
                </Col>
                <Col xl={4} className="right-col-child d-none d-xl-flex">
                  <img src={mobile} alt="mobile" />
                </Col>
              </Row>
            </Container>
          </Col>
          <div className="position-fixed ministry-logo" >
            <img src={l1feMinistry} width={142} alt="l1fe ministy" />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Home;
