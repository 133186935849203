import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import l1feMinistry from "./../../assets/images/l1feMinistry.png";
import life from "./../../assets/icons/lifeGroup.png";
import { supabase } from '../../utils/supabaseClient';
import LeftColoumn from '../../components/shared/LeftColoumn';

const DashboardPage = () => {

    const handleLogout = async () => {
        try {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("sb-ajawgzdjkbfgnjxnbcmw-auth-token");
            localStorage.removeItem("oauth_provider_token");
            await supabase.auth.signOut();
            window.location.href = "/login";
        } catch (error) {
            console.error('Logout error:', error.message);
        }

    }
    return (
        <Container fluid className="vh-100 login-wrapper wrapper">
            <Row className="h-100">
                <LeftColoumn />
                <Col xl={7} className="right-col mt-5">
                    <Container className="h-100">
                        <Row className="h-100">
                            <Col lg={{ span: 6, offset: 2 }} className="d-flex">
                                <div className="w-100">
                                    <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
                                        <div className="icon-block">
                                            <img
                                                src={life}
                                                alt="life"
                                                className="tab_desk"
                                                style={{ width: '100px' }}
                                            />
                                        </div>
                                        <button className="btn btn-danger btn-lg btn-block btn-login"
                                            onClick={handleLogout}
                                        >Logout</button>
                                    </div>
                                    <h2>Welcome to the L1FE Dashboard</h2>
                                    <p>Coming Soon</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <div className="position-fixed ministry-logo" >
                    <img src={l1feMinistry} width={142} alt="l1fe ministy" />
                </div>
            </Row>
        </Container>
    )
}

export default DashboardPage;