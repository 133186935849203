import { Col } from "react-bootstrap";
import dSocialLogo from "../../assets/icons/dSocialLogo.png";
import leftbgCenter from "../../assets/images/leftbgcenter.png";

const LeftColoumn = () => {
  return (
    <>
      <div className="position-fixed z-3 social-logo mb-2">
        <img className="py-3 ml-2" src={dSocialLogo} width={127} alt="dsocial logo" />
      </div>
      <Col xl={5} className="left-col position-relative">
        <div className="position-absolute translate-middle ministry-wrapper">
          <img src={leftbgCenter} className="center-logo" width={317} alt="l1fe ministy" />
        </div>
      </Col>
    </>
  )
}

export default LeftColoumn