import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import life from "./../../assets/icons/lifeGroup.png";
import l1feMinistry from "./../../assets/images/l1feMinistry.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LeftColoumn from "../../components/shared/LeftColoumn";

const SignUp = () => {
  const navigate = useNavigate();
  return (
    <Container fluid className="vh-100 login-wrapper wrapper">
      <Row className="h-100">
        <LeftColoumn />
        <Col xl={7} className="right-col mt-4">
          <Container className="h-100">
            <Row className="h-100">
              <Col lg={{ span: 6, offset: 3 }} className="auth-block">
                <div className="w-100">
                  <div className="icon-block">
                    <img
                      src={life}
                      alt="life"
                      className="tab_desk"
                      style={{ width: '100px' }}
                    />
                  </div>
                  <div className="verify-otp text-center final-step">
                    <div className="mt-32 mb-24">
                      <p className="thankyou-title fs-5 fw-semibold mb-5">
                        Thank you for creating your lifeID and your interest in dSocial
                      </p>
                      <p className="thankyou-para">
                        We’re in the process of launching dSocial and will notify you when
                        the official ALPHA version of the app launches publicly.
                      </p>
                      <p className="thankyou-para">
                        In the meantime, check out our L1FE dashboard where you can manage
                        your L1FE membership.
                      </p>
                    </div>
                    <Button
                      className="custom-btn thankyou-button mb-24"
                      variant="dark"
                      // disabled={true}
                      // onClick={() => setStep((prev) => prev + 1)}
                      onClick={() => navigate('/login')}
                    >
                      Got to L1FE Dashboard
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <div className="position-fixed ministry-logo" style={{
          paddingLeft: "40px",
        }}>
          <img src={l1feMinistry} width={142} alt="l1fe ministy" />
        </div>
      </Row>
    </Container>
  );
};

export default SignUp;
