import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../../../utils/axios";
import { API } from "../../../utils/apiendpoints";
import twitter from "./../../../assets/icons/twitter.svg";
import emailIcon from "./../../../assets/icons/emailIcon.png";
import Input from "../../shared/Input";
import { supabase } from "../../../utils/supabaseClient";

const Step1 = ({ setStep, children }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [socialId, setSocialId] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const [googleEmail, setgoogleEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams?.get("googleEmail")) {
      setgoogleEmail(true);
      setValue("email", queryParams?.get("googleEmail"));
    }
    supabase.auth.onAuthStateChange((event, session) => {
      if (session && session.provider_token) {
        window.localStorage.setItem('oauth_provider_token', session.provider_token)
      }

      if (session && session.provider_refresh_token) {
        window.localStorage.setItem('oauth_provider_refresh_token', session.provider_refresh_token)
      }

      if (event === 'SIGNED_OUT') {
        window.localStorage.removeItem('oauth_provider_token')
        window.localStorage.removeItem('oauth_provider_refresh_token')
      }
    })

  }, []);

  const onFormSubmit = (data) => {
    trigger()
      .then((res) => {
        if (res) {
          setStep((prev) => prev + 1);
          navigate({
            search: new URLSearchParams(data).toString(),
          });
        }
      })
      .catch((err) => console.log(err));
  };


  const EmailVerify = async (event) => {
    const enteredEmail = event.target.value;
    if (enteredEmail.match(/^[\w.+-]+@([\w-]+\.)+[\w-]{2,}$/)) {
      try {
        await instance.get(`${API.checkEmail}/${enteredEmail}`).then((res) => {
          if (!res?.error) {
            if (res?.data?.status === true) {
              setError("email", {
                type: "manual", // You can use 'manual' type for custom errors
                message: `${res?.data?.message}`,
              });
            } else {
              setError("email", null);
            }
          }
        });
        // setIsEmailVerified(response.data.message);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const signinwithTwitter = async () => {
    try {
      setLoader(true);
      await supabase.auth.signInWithOAuth({
        provider: "twitter",
        options: {
          redirectTo: `${process.env.REACT_APP_HOME_URL}/signup`
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="social-group">
        <div className="social-signup-btn" onClick={signinwithTwitter}>
          <img src={twitter} alt="Twitter" width={18} height={18} /> <span>Continue with Twitter</span>
        </div>
      </div>
      {/* format */}
      <div className="my-4"
        style={{
          display: "flex",
          alignItems: "baseline"
        }}
      >
        <div
          style={{
            color: "#46453C",
            fontSize: 14,
            flexBasis: "35%",
          }}
        >
          Or better yet...
        </div>
        <hr style={{ width: "100%" }} />
      </div>
      {/* format */}
      <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mt-32">
        <div className="w-100 mb-32">
          <Input
            name="email"
            placeholder="Enter Your Email address"
            icon={emailIcon}
            disabled={socialId || googleEmail}
            errors={errors}
            register={register("email", {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid Email",
              },
              required: {
                value: true,
                message: "This is required",
              },
              onChange: EmailVerify,
            })}
          />
        </div>
        <p className="bottom-text">
          By creating an account, you agree to our Terms of Service and Privacy & Cookie Statement.
        </p>
        <Button
          disabled={errors?.email?.message}
          className="custom-btn"
          variant="dark"
          type="submit"
        >
          Next
        </Button>
        {children}
      </form>
    </>
  );
};

export default Step1;
