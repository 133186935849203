import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import twitter from "./../../assets/icons/twitter.svg";
import Input from "../shared/Input";
import PasswordInput from "../shared/PasswordInput";
import instance from "../../utils/axios";
import { API } from "../../utils/apiendpoints";
import { supabase } from "../../utils/supabaseClient";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (session && session.provider_token) {
        window.localStorage.setItem('oauth_provider_token', session.provider_token)
      }

      if (session && session.provider_refresh_token) {
        window.localStorage.setItem('oauth_provider_refresh_token', session.provider_refresh_token)
      }

      if (event === 'SIGNED_OUT') {
        window.localStorage.removeItem('oauth_provider_token')
        window.localStorage.removeItem('oauth_provider_refresh_token')
      }
    })
  }, []);

  const onFormSubmit = (data) => {
    setLoader(true);
    instance
      .post(API.loginUser, data)
      .then((res) => {
        if (!res?.error) {
          setLoader(false);
          localStorage.setItem("accessToken", res.data?.data?.accessToken);
          window.location.href = "/dashboard";
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.data.message === "You are not verified. Kindly verify your account to login.") {
          navigate(`/signup?email=${data.email}&step=3`);
        }
      });
  };

  const signinwithTwitter = async () => {
    try {
      setLoader(true);
      await supabase.auth.signInWithOAuth({
        provider: "twitter",
        options: {
          redirectTo: `${process.env.REACT_APP_HOME_URL}/login`
        }
      })
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="social-group">
        <div className="social-signup-btn" onClick={signinwithTwitter}>
          <img src={twitter} alt="Twitter" width={18} height={18} /> <span>Continue with Twitter</span>
        </div>
      </div>

      {/* format */}
      <div className="my-4"
        style={{
          display: "flex",
          alignItems: "baseline"
        }}
      >
        <div
          style={{
            color: "#46453C",
            fontSize: 14,
            flexBasis: "35%",
          }}
        >
          Or better yet...
        </div>
        <hr style={{ width: "100%" }} />
      </div>
      {/* format */}

      <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mb-32">
        <div className="w-100 mb-18 spacingErr">
          <Input
            name="email"
            placeholder="Enter Your Email address"
            errors={errors}
            register={register("email", {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid Email",
              },
              required: {
                value: true,
                message: "This is required",
              },
            })}
          />
        </div>
        <div className="w-100 spacingErr">
          <PasswordInput
            name="password"
            placeholder="Enter Your Password"
            errors={errors}
            register={register("password", {
              required: {
                value: true,
                message: "This is required",
              },
              pattern: {
                value:
                  // eslint-disable-next-line no-useless-escape
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]])[a-zA-Z\d~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]]{8,}$/,
                message:
                  "Password must be 8 characters and must contain atleast 1 small alphabet, 1 capital alphabet, 1 numeric value and 1 special character",
              },
              minLength: {
                value: 8,
                message: "Password should contain minimum 8 characters long",
              },
              maxLength: {
                value: 20,
                message: "Password should contain maximum 20 characters long",
              },
            })}
          />
        </div>

        {/* <a href="/reset-password" className="custom-a cursor-pointer">
          Forget Password?
        </a> */}
        <p className="bottom-text">
          By creating an account, you agree to our Terms of Service and Privacy & Cookie Statement.
        </p>
        <Button
          className="custom-btn mb-32"
          // style={{ marginTop: "24px" }}
          variant="dark"
          type="submit"
        >
          {loader ? (
            <>
              <span
                className="spinner-border me-2 spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </>
          ) : (
            "Login"
          )}
        </Button>
      </form>
    </>
  );
};

export default Login;
